import type { FC } from 'react'
import { memo } from 'react'

import Button from '@base/buttons/Button'
import { PRIVACY_LINK, TERMS_LINK } from '@rnConstants/links/termsAndPolicy'

export type TermsAndConditionsButtonsProps = {
  privacyLabel: string
  termsLabel: string
}

const TermsAndConditionsButtons: FC<TermsAndConditionsButtonsProps> = ({ privacyLabel, termsLabel }) => {
  return (
    <>
      <Button href={TERMS_LINK} target="_blank" type="link">
        {termsLabel}
      </Button>
      <Button href={PRIVACY_LINK} target="_blank" type="link">
        {privacyLabel}
      </Button>
    </>
  )
}

export default memo(TermsAndConditionsButtons)
